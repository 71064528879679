import * as React from "react"

const NoScript = () => (
  <noscript>
    <p className="text-red-600 text-2xl font-bold">
      This page will need javascript in order to work.
    </p>
  </noscript>
)

export default NoScript
