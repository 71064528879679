import * as React from "react"
import { useState } from "react"

import Layout from "../components/layout"
import NoScript from "../components/noscript"
import Seo from "../components/seo"

import spelling_names_json from "../data/spelling_names.json"

const ContactPage = () => {
  const [value, setValue] = useState('Dardan Haxhimustafa');
  const [lang, setLang] = useState('at');
  return <Layout>
    <Seo title="Spell Tool" />
    <h1>Spell (Buchstabieren)</h1>
    <NoScript/>
    <div className="my-3 tablet:flex tablet:flex-row">
      <input type='text' value={value} className='bg-white border border-solid border-black px-2 py-1 w-full tablet:w-auto m-1 tablet:grow' onChange={a => setValue(a.target.value)}/>
      <select onChange={a => setLang(a.target.value)} className='w-full tablet:w-auto m-1 px-1 py-2'>
        {
          [
            {
              code: 'at', 
              name: 'Österreichisch'
            },
            {
              code: 'de', 
              name: 'Deutsch'
            },
            {
              code: 'ch', 
              name: 'Schweizerdeutsch'
            },
            {
              code: 'int', 
              name: 'NATO phonetic alphabet'
            }
          ].map(item => (
          <option value={item.code} selected={item.code === lang}>
            {item.name}
          </option>
          ))
        }
      </select>
    </div>
    <ul>
      {[...value].map(character => (
        <li className="animate-show-up">{get_spelling(character, lang)}</li>
      ))}
    </ul>
    <p className="text-sm mt-8">
      * This tool is not optimal, as it will ignore the special spellings. E.g. instead of showing "schule" for "sch" it shows the spellings for "s", "c" and "h".
      It also doesn't include every single special character.
    </p>
    
  </Layout>;
};

const get_spelling = (character, language) => {
  if (character === ' ') {
    return <hr className="border border-gray"/>;
  }
  const c = character.toUpperCase();
  return (spelling_names_json[c] || {})[language] || '?';
};

export default ContactPage
